import { Popover } from "antd";
import * as R from "ramda";
import "./publishers.scss";

interface PublisherPopoverProps {
  item: {
    publisher_id: number;
    publisher: string;
    logo: string;
    pricelist: string;
    packages: { title: string; url: string }[]; // Add 'url' property to the packages
  };
}

const PublisherPopover = function ({ item }: PublisherPopoverProps) {
  const { publisher_id, publisher, packages, logo, pricelist } = item;
  return (
    <Popover
      placement="bottomLeft"
      title={<strong>Pakete und deren Titel (<a href={pricelist} target="_blank" rel="noopener noreferrer">Preis- und Titelliste des Verlages</a>)</strong>}
      content={
        <ul className="packages-content">
          {R.defaultTo([], packages).map(
            (
              { title, url }: any // Add 'url' here
            ) => (
              <li
                className="list-small"
                key={`${publisher_id}-package-${title}`}
              >
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {title}
                </a>
              </li>
            )
          )}
        </ul>
      }
      trigger="click"
    >
      <img
        src={logo}
        alt={`Logo for ${publisher}`}
        style={{ maxHeight: "25px", width: "auto", maxWidth: "100%" }}
      />
    </Popover>
  );
};

export default PublisherPopover;
