import React, { useEffect, useState } from "react";
import { Collapse, Select, Table } from "antd";
import * as R from "ramda";
import * as RA from "ramda-adjunct";
import PublisherPopover from "./PublisherPopover";
import "./matrix.scss";
import {
  CategoryWithChildren,
  MatrixResponseContent,
  PublisherWithPackages,
} from "./types/matrix";

const { Panel } = Collapse;

interface ICategory {
  id: number | string;
  name: string;
  children: ICategory[];
}

const columns = [
  {
    title: "Fachgebiet",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Verlage",
    dataIndex: "publishers",
    key: "publishers",
    render: (publishers: PublisherWithPackages[]) => (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {" "}
        {/* Add a flex container */}
        {publishers.map((publisher) => (
          <div
            key={`publisher-id-${publisher.publisher_id}`}
            style={{ margin: "10px" }}
          >
            {" "}
            {publisher.logo ? (
              <PublisherPopover
                key={`publisher-id-${publisher.publisher_id}`}
                item={publisher}
              />
            ) : (
              <span>No Logo Available</span>
            )}
          </div>
        ))}
      </div>
    ),
  },
];

interface MatrixResponse {
  data: MatrixResponseContent | null;
  isLoading: boolean;
  filteredPublishers: number[];
  filteredCategories: string[];
}

function Matrix() {
  const [
    { data, isLoading, filteredPublishers, filteredCategories },
    setResponseState,
  ] = useState<MatrixResponse>({
    data: null,
    isLoading: true,
    filteredPublishers: [],
    filteredCategories: [],
  } as MatrixResponse);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  useEffect(() => {
    fetch("/api/matrix/v2.json")
      .then((data) => data.json())
      .then((data: MatrixResponseContent) =>
        setResponseState({
          data,
          isLoading: false,
          filteredPublishers: [],
          filteredCategories: data?.categories?.map(
            ({ Category: { id } }) => id
          ),
        })
      );
  }, []);

  const handlePublishersChange = (values: string[]) => {
    const categoriesWithPublishers = data?.matrix.filter(({ publishers }) => {
      const publishersInCategory = R.pluck("publisher_id", publishers);
      return R.intersection(values, publishersInCategory)?.length;
    });
    const childrenCategoriesId = R.map(
      R.path(["category", "id"]),
      categoriesWithPublishers
    );

    const parentCategories = data?.categories
      .filter(({ children }) => {
        const childrenIds = R.map(
          R.pipe(R.path(["Category", "id"]), (value: string) =>
            parseInt(value, 10)
          ),
          children
        );
        return R.intersection(childrenCategoriesId, childrenIds)?.length;
      })
      .map(({ Category }) => parseInt(Category.id, 10));

    const activeKeys = R.map(
      (id: number) => `category-panel-${id}`,
      parentCategories
    );

    setActiveKeys(R.uniq(activeKeys));
    setResponseState({
      data,
      isLoading,
      filteredCategories,
      filteredPublishers: values.map((value) => parseInt(value)),
    });
  };

  const handleCategoriesChange = (values: string[]) => {
    const _filtered = values?.length
      ? values
      : data?.categories?.map(({ Category: { id } }) => id) || [];

    setActiveKeys(R.uniq(values.map((id) => `category-panel-${id}`)));
    setResponseState({
      data,
      isLoading,
      filteredCategories: _filtered,
      filteredPublishers,
    });
  };

  const handleCollapseToggled = (value: string | string[]) => {
    const keys = R.is(String, value) ? [value as string] : (value as string[]);
    setActiveKeys(keys);
  };

  if (isLoading) {
    return <span>loading...</span>;
  }

  const publisherOptions = R.pipe(
    R.propOr([], "publishers"),
    R.map(
      R.pipe(
        R.propOr({}, "Publisher"),
        RA.renameKeys({ id: "value", name: "label" })
      )
    )
  )(data);

  const categories = R.pipe(
    R.propOr([], "categories"),
    R.map((category: CategoryWithChildren) => {
      if (R.isNil(category.Category) || R.isEmpty(category.Category)) {
        return null;
      }
      const { Category, children } = category;
      const { name, id } = Category;
      return {
        id,
        name,
        children: R.pipe(
          R.defaultTo([]),
          R.map(R.propOr(null, "Category")),
          R.reject(R.isNil),
          R.map(R.pick(["id", "name"]))
        )(children),
      };
    }),
    R.reject(R.isNil)
  )(data);

  const indexedCategories = R.indexBy(R.prop("id"), categories);

  const categoryOptions = R.pipe(
    R.map(
      R.pipe(
        R.pick(["id", "name"]),
        RA.renameKeys({ id: "value", name: "label" })
      )
    )
  )(categories);

  const mappedMatrixByCategoryId: Record<
    string | number,
    PublisherWithPackages[]
  > = R.pipe(
    R.propOr([], "matrix"),
    R.indexBy(R.path(["category", "id"])),
    R.mapObjIndexed(R.propOr([], "publishers"))
  )(data);

  const filterMatrixByPublishers = (matrix: PublisherWithPackages[]) => {
    if (R.isNil(filteredPublishers) || R.isEmpty(filteredPublishers)) {
      return matrix;
    }
    const hasPublisher = R.find(
      R.pipe(
        R.propOr(null, "publisher_id"),
        R.includes(R.__, filteredPublishers)
      ),
      matrix
    );

    return hasPublisher ? matrix : [];
  };

  const pickDataSource = (categories: ICategory[]) => {
    return R.pipe(
      R.map((category: ICategory) => {
        const matrix = mappedMatrixByCategoryId[parseInt(`${category.id}`)];
        const publishers = filterMatrixByPublishers(matrix);
        if (R.isNil(publishers) || R.isEmpty(publishers)) {
          return null;
        }
        return { id: category.id, name: category.name, publishers };
      }),
      R.reject(R.isNil)
    )(categories) as any[];
  };

  const renderTable = (categories: ICategory[]) => {
    if (R.isEmpty(categories) || R.isNil(categories)) {
      return null;
    }
    return (
      <Table
        columns={columns}
        rowKey={R.prop("id")}
        dataSource={pickDataSource(categories)}
        pagination={false}
      />
    );
  };

  return (
    <>
      <nav className="nav bg-dark">
        <div className="container">
          <img
            className="logo"
            src="/logo-content-select.png"
            alt="Logo Content-Select"
          />
        </div>
      </nav>
      <div className="main">
        <div className="container">
          <div className="select-grid">
            <Select
              mode="multiple"
              allowClear
              placeholder="Bitte wählen Sie ein Sachgebiet"
              optionFilterProp="label"
              onChange={handleCategoriesChange}
              options={categoryOptions}
            />
            <Select
              mode="multiple"
              allowClear
              placeholder="Bitte wählen Sie einen Verlag"
              optionFilterProp="label"
              onChange={handlePublishersChange}
              options={publisherOptions}
            />
          </div>

          <Collapse activeKey={activeKeys} onChange={handleCollapseToggled}>
            {filteredCategories.map((id: string) => (
              <Panel
                header={indexedCategories[id].name}
                key={`category-panel-${id}`}
              >
                {renderTable(indexedCategories[id].children || [])}
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </>
  );
}

export default Matrix;
